import React from 'react';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { Link } from 'gatsby';
import { Col, Container, Row } from 'reactstrap';
import SEO from 'components/SEO';
import Hero from 'components/Hero';
import Layout from 'components/Layout';
import Section from 'components/Section';
import SmallPrint from 'components/SmallPrint';
import TastyButton from 'components/TastyButton';
import ReadyToTrade from 'components/ReadyToTrade';
import SectionDivider from 'components/SectionDivider';
import SectionsWrapper from 'components/SectionsWrapper';
import appreciationHero from 'images/TT1506_TW-LPD01-v1.png';
import mobileHero from 'images/TT1442_TW-LP-HERO01c_r3.png';
import { useLayoutContext } from 'context/LayoutContext';
import externalLinks from 'utils/externalLinks';

const AppreciationWrapper = styled(SectionsWrapper)`
  padding-bottom: 2rem;

  ${SmallPrint} {
    font-style: normal;
    margin-bottom: 0;

    a {
      color: ${prop('theme.colors.primaryRed')};
      font-weight: 700;
      text-decoration: none;

      &:hover {
        color: ${prop('theme.colors.gray')};
      }
    }
  }
`;

const AppreciationHeroWrapper = styled.div`
  background-color: #0f3677;

  @media (min-width: ${prop('theme.breakpoints.lg')}) {
    padding-top: 1rem;
  }
`;

const AppreciationHero = styled(Hero)`
  &&& {
    @media (min-width: ${prop('theme.breakpoints.lg')}) {
      margin-top: 10.0625rem;
    }

    img {
      @media (max-width: ${prop('theme.breakpoints.md')}) {
        height: 100%;
        margin: 0;
        width: 100%;
      }
    }
  }
`;

const StyledDiv = styled.div`
  padding: 3.75rem 0;

  ${ifProp('withDivider', css`
      padding-bottom: 1.75rem;
    `
  )}

  ${ifProp('disclaimer', css`
      padding: 1.75rem 0;
    `
  )}

  sup {
    color: ${prop('theme.colors.primaryRed')};
    font-size: 100%;
    top: -0.35em;
  }
`;

const AppreciationCopy = styled.p`
  &&& {
    font-size: 0.875rem;

    @media (min-width: ${prop('theme.breakpoints.sm')}) {
      font-size: 1.125rem;
    }

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      font-size: 1.25rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const TermsConditionsList = styled.ol`
  font-family: ${prop('theme.fonts.default')};
  margin-bottom: 0;
  padding-left: 1.25rem;
`;

const ListItem = styled.li`
  font-size: 0.875rem;
  padding-bottom: 0.875rem;

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    font-size: 1.125rem;
  }

  &:last-child {
    padding-bottom: 0;
  }
`;

function AppreciationPage({ showPromoBar }) {
  const { state: layoutContextState } = useLayoutContext();
  const { showAlert } = layoutContextState;

  return (
    <Layout>
      <SEO title='Trader Appreciation Day' canonicalHref='https://tastytrade.com/appreciation' />
      <AppreciationHeroWrapper>
        <Container>
          <AppreciationHero
            hideGradient
            heroImage={appreciationHero}
            mobileHero={appreciationHero}
            heroType='mobile'
          />
        </Container>
      </AppreciationHeroWrapper>
      <AppreciationWrapper noGutters>
        <Section withPadding>
          <StyledDiv>
            <AppreciationCopy>
              You picked us over other brokerages. You inspire us to build a better
              platform. You’re patient for new features and products. How could we
              ever thank you?
            </AppreciationCopy>
            <AppreciationCopy>
              What about a whole day with no commissions?
              Trader Appreciation Day is April 21st during US market hours (8:30am - 3:15pm CST).
              We won’t charge commissions on any trades, at all.
              Enjoy your commission-free trades. You’ve earned ‘em.<sup>*</sup>
            </AppreciationCopy>
          </StyledDiv>
        </Section>
        <ReadyToTrade />
        <Section withPadding>
          <StyledDiv withDivider>
            <p>
              <sup>*</sup>
              <span className='font-weight-bold'>Terms & Conditions</span>
            </p>
            <TermsConditionsList>
              <ListItem>
                This promotion is applicable only to equity options product commissions.
              </ListItem>
              <ListItem>
                This promotion is for free commissions only. Clearing, regulatory,
                exchange, and proprietary index options fees still apply.
              </ListItem>
              <ListItem>
                This promotion does not apply to Smalls futures, CME futures,
                options on futures, or cryptocurrency commissions.
              </ListItem>
              <ListItem>
                There is no restriction on account size or type of account.
              </ListItem>
              <ListItem>
                This offer is valid for new or existing tastytrade account
                holders.
              </ListItem>
              <ListItem>
                Offer expires 4/21/2022 at 3:15PM CST. Any trades past this deadline
                will be subject to all applicable fees and commissions. We are not
                responsible for any delays in trading.
              </ListItem>
              <ListItem>This promotion has no cash value.</ListItem>
              <ListItem>This promotion is not transferable.</ListItem>
              <ListItem>Void where prohibited by law.</ListItem>
            </TermsConditionsList>
          </StyledDiv>
        </Section>
        <Section withPadding>
          <SectionDivider />
          <StyledDiv disclaimer>
            <SmallPrint bold>Disclaimer</SmallPrint>
            <SmallPrint>
              Securities & Options trading is not suitable for all investors as
              the special risks inherent to securities options trading may expose
              investors to potentially rapid and substantial losses. Please read
              {' '}
              <a
                href={externalLinks.standardizedOptionsRisks}
                rel='noopener noreferrer'
                target='_blank'
              >
                Characteristics and Risks of Standardized Options
              </a>
              {' '}
              before investing in options.
            </SmallPrint>
          </StyledDiv>
        </Section>
      </AppreciationWrapper>
    </Layout>
  )
}

export default AppreciationPage;
